import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Tooltip,
    Button,
    Autocomplete,
    TextField,
    Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

function SpacesMarketReport() {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().subtract(21, 'day'));
    const [endDate, setEndDate] = useState(dayjs());
    const [site, setSite] = useState();

    const sites = [
        'soo.spaces.ca',
        'sudbury.spaces.ca',
        'burlington.spaces.ca',
        'guelph.spaces.ca',
        'northbay.spaces.ca',
        'oakville.spaces.ca',
        'cambridge.spaces.ca',
        'collingwood.spaces.ca',
        'barrie.spaces.ca',
        'orillia.spaces.ca'
    ]

    // TODO: Graph SPACES members by Space (complete, not great. messaged Jeff on Mar 6)
    // TODO: Graph Total number of Members to the site (messaged Scott on Mar 5)
    // TODO: Understanding where people initiate the flow and where they came from before that (what button did they click to sign up)
    // TODO: Where did people convert from (SooToday, Google, etc.)?
    // TODO: Identify each flow entry point and what steps show up with their journey. Start in GA4 and then move into Brettalytics if necessary

    useEffect(() => {
        // Fetch audiences on component mount
        if (site) {
            setLoading(true)
            fetch(`/api/spaces-analytics?site=${site}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`)
                .then((res) => res.json())
                .then((data) => {
                    setResults(data)
                    setLoading(false)
                    console.log(data.postViewsData)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        }
    }, [site, startDate, endDate]);

    const columns = [
        {
            field: 'space', headerName: 'Space Slug', flex: 2, renderCell: (params) => (
                <Link
                    to={`/spaces/analytics/space`}
                    state={{
                        space: params.value,
                        site: site,
                    }}
                >
                    {params.value}
                </Link>
            )
        },
        {
            field: 'sessions', headerName: 'Sessions', flex: 1.5, renderCell: (params) => (
                <>
                    {Number(params.value).toLocaleString()}
                </>
            )
        },
        {
            field: 'posts', headerName: 'Posts', flex: 1.5, renderCell: (params) => (
                <>
                    {Number(params.value).toLocaleString()}
                </>
            )
        },
        {
            field: 'members', headerName: 'Members', flex: 1.5, renderCell: (params) => (
                <>
                    {Number(params.value).toLocaleString()}
                </>
            )
        },
        {
            field: 'comments', headerName: 'Comments', flex: 1.5, renderCell: (params) => (
                <>
                    {Number(params.value).toLocaleString()}
                </>
            )
        },
        {
            field: 'reactions', headerName: 'Reactions', flex: 1.5, renderCell: (params) => (
                <>
                    {Number(params.value).toLocaleString()}
                </>
            )
        }
    ];



    const handleExportCSV = () => {
        const csvData = results.combinedSpaces.map((item, index) => {
            if (index === 0) {
                return [`Space,Views,Sessions,Posts,Members,Comments,Reactions,\n${item.space},${item.views},${item.sessions},${item.posts},${item.members},${item.comments},${item.reactions}`].join('\n');
            }
            const csvRow = [`${item.space},${item.views},${item.sessions},${item.posts},${item.members},${item.comments},${item.reactions}`];
            return csvRow.join('\n');
        }).join('\n');

        // Create a Blob from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a link to download the Blob
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'spaces_report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Container maxWidth="lg">
            <Alert severity="info" sx={{ marginBottom: 3 }}>
                The Spaces report is currently in beta and may not be available for all sites. Data for SOO SPACES is available for time periods starting February 1, 2025. Data for other sites will not be available for time periods before March 14, 2025.
                <br /><br />
                Post views metrics are only available for periods starting March 27, 2025.
                <br /><br />
                Additionally, Registered User numbers are effective March 31, 2025.
            </Alert>
            <Grid2
                container
                spacing={3}
            >
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                        <Autocomplete
                            disablePortal
                            renderInput={(params) => <TextField {...params} label="Site" />}
                            value={site}
                            options={sites}
                            onInputChange={(e, value, reason) => { if (reason === "selectOption") { setSite(value) } }}
                        />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="Start Date"
                        value={startDate}
                        onChange={(newDate) => {
                            setStartDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("01-31-2025")}
                        maxDate={dayjs().subtract(2, "day")}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                            setEndDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("08-01-2024")}
                        maxDate={dayjs()} />
                </Grid2>
            </Grid2>
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                results ? (
                    <>
                        <Grid2 container spacing={3} marginTop={3}>
                            <Grid2 container spacing={3} size={{ xs: 12, sm: 12, md: 12 }}>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Post Views <Tooltip placement="top" title="Total post views for the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.postViewsData[0] ? results.postViewsData[0].postViews : "0").toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                {/*<Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Pageviews <Tooltip placement="top" title="Total pageviews for the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>*/}
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Sessions <Tooltip placement="top" title="Total sessions for the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>

                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Users <Tooltip placement="top" title="Total users for the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.totalUsers).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Post Count <Tooltip placement="top" title="Total posts created within the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{results.periodGrowth.posts.toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Comments Count <Tooltip placement="top" title="Total comments created within the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{results.periodGrowth.comments.toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Reaction Count <Tooltip placement="top" title="Total reactions within the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{results.periodGrowth.reactions.toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Registered Users <Tooltip placement="top" title="The number of registered users up to the end date of the current reporting period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{results.spacesMarketStats[0] ? results.spacesMarketStats[0].registered_users.toLocaleString() : "N/A"}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Empty Spaces <Tooltip placement="top" title="The number of spaces that have no posts within the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{results.emptyRooms.toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Active Spaces <Tooltip placement="top" title="Total number of spaces that have posts within the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{results.activeRooms.toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                        </Grid2>

                        <Typography variant="h4" style={{ marginTop: '20px' }}>Spaces Leaderboard</Typography>

                        <Grid2>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleExportCSV}
                                sx={{ marginTop: '20px' }}
                            >
                                Export CSV
                            </Button>
                        </Grid2>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.combinedSpaces} columns={columns} />
                    </>
                ) : (
                    <></>
                )
            )
            }
        </Container >
    );
}

export default SpacesMarketReport;
