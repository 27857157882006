import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Container,
  Alert,
  Button,
  TextField
} from '@mui/material';

function FaviconGenerator() {

  const [script, setScript] = useState({ color: "#1665a8" });
  const [alert, setAlert] = useState();

  const scriptText = `<meta name="theme-color" content="${script.color}">
<link rel="icon" href="${script.svg}">
<link rel="mask-icon" href="${script.maskSvg}" color="${script.color}">
<link rel="apple-touch-icon" href="${script.png}">
  `

  const handleChange = (e) => {
    const color = e.color ? e.color : script.color;
    const svg = e.svg ? e.svg : script.svg;
    const maskSvg = e.maskSvg ? e.maskSvg : script.maskSvg;
    const png = e.png ? e.png : script.png;

    setScript({ color, svg, maskSvg, png });
  }

  const copyToClipboard = (adCode) => {
    navigator.clipboard.writeText(adCode);
    setAlert(true);
  };

  return (
    <Container maxWidth="lg">
      <Box component="form">
        <FormControl fullWidth margin="normal">
          <TextField id="color" label="Color (hexcode)" value={script.color} onChange={(e) => handleChange({ color: e.target.value })} />
        </FormControl>

        <FormControl fullWidth margin="normal">
            <TextField id="svg" label="SVG (link)" value={script.svg} onChange={(e) => handleChange({ svg: e.target.value })} />
        </FormControl>

        <FormControl fullWidth margin="normal">
            <TextField id="maskSvg" label="Mask SVG (link)" value={script.maskSvg} onChange={(e) => handleChange({ maskSvg: e.target.value })} />
        </FormControl>

        <FormControl fullWidth margin="normal">
            <TextField id="png" label="PNG (link)" value={script.png} onChange={(e) => handleChange({ png: e.target.value })} />
        </FormControl>
      </Box>

      <Typography variant="h5" mt={3}>Favicon Tags:</Typography>
      {script.color && script.svg && script.png && script.maskSvg && (
        <Box>
          {alert && (
            <Alert severity='success'>Tag copied to Clipboard</Alert>
          )}
          <Button onClick={() => copyToClipboard(scriptText)}>Copy to Clipboard</Button>
          <pre>
            <code>
              {scriptText}
            </code>
          </pre>
        </Box>
      )}
    </Container>
  );
}

export default FaviconGenerator;
