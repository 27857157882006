import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Paper,
    Switch,
    Tooltip,
    Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LineChart } from '@mui/x-charts';
import { useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';

function SpacesReport() {
    const location = useLocation();
    const state = location.state || {};
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().subtract(21, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));
    const [isChange, setIsChange] = useState(true);
    const { space, site } = state;

    

    useEffect(() => {
        // Fetch audiences on component mount
        if (site) {
            setLoading(true)
            fetch(`/api/spaces-analytics?site=${site}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&space=${space ? space : 'city-info'}`)
                .then((res) => res.json())
                .then((data) => {
                    setResults(data)
                    setLoading(false)
                    console.log(data.postViewsData)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        }
    }, [site, startDate, endDate, space]);

    const columns = [
        {
            field: 'date', headerName: 'Date', flex: 2, type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        { field: 'space', headerName: 'Space', flex: 2 },
        { field: 'posts', headerName: 'Posts', flex: 2 },
        { field: 'comments', headerName: 'Comments', flex: 2 },
        { field: 'reactions', headerName: 'Reactions', flex: 2 },
        { field: 'members', headerName: 'Members', flex: 2 },
    ];

    const columnsChange = [

        {
            field: 'date', headerName: 'Date', flex: 2, type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        { field: 'space', headerName: 'Space', flex: 2 },
        { field: 'newPosts', headerName: 'Posts Change', flex: 2 },
        { field: 'newComments', headerName: 'Comments Change', flex: 2 },
        { field: 'newReactions', headerName: 'Reactions Change', flex: 2 },
        { field: 'newMembers', headerName: 'Members Change', flex: 2 },
    ]

    const handleExportCSV = () => {
        const csvData = results.spaceOverview.map((item, index) => {
            if (index === 0) {
                return [`Date,Space,Posts,Comments,Reactions,Members,New Posts,New Comments,New Reactions,New Members\n${item.date},${item.space},${item.posts},${item.comments},${item.reactions},${item.members},${item.newPosts},${item.newComments},${item.newReactions},${item.newMembers}`].join('\n');
            }
            const csvRow = [`${item.date},${item.space},${item.posts},${item.comments},${item.reactions},${item.members},${item.newPosts},${item.newComments},${item.newReactions},${item.newMembers}`];
            return csvRow.join('\n');
        }).join('\n');

        // Create a Blob from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a link to download the Blob
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'spaces_report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <Container maxWidth="lg">
            <Grid2
                container
                spacing={3}
            >
                <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                    <Typography variant="h4">
                        Space: {space ? space : 'city-info'}
                    </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="Start Date"
                        value={startDate}
                        onChange={(newDate) => {
                            setStartDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("01-31-2025")}
                        maxDate={dayjs().subtract(2, "day")}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                            setEndDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("01-31-2025")}
                        maxDate={dayjs().subtract(1, "day")} />
                </Grid2>
                <Grid2 size={{ xd: 12, sm: 6, md: 3 }} alignContent="center">
                    <Typography variant="caption">
                        Filter changes:
                    </Typography>
                    <Switch
                        checked={isChange}
                        onChange={(event) => setIsChange(event.target.checked)}
                        label="Show changes"
                    />
                </Grid2>
            </Grid2>
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                results ? (
                    <>
                        {!isChange &&
                            <LineChart
                                xAxis={[{
                                    data: results.spaceOverview.map((item) => dayjs(item.date).unix()),
                                    valueFormatter: (value) => dayjs.unix(value).format("MM/DD/YYYY")
                                }]}
                                series={[
                                    {
                                        data: results.spaceOverview.map((item) => item.members),
                                        valueFormatter: (value) => `Members: ${value}`
                                    },
                                    {
                                        data: results.spaceOverview.map((item) => item.posts),
                                        valueFormatter: (value) => `Posts: ${value}`
                                    },
                                    {
                                        data: results.spaceOverview.map((item) => item.comments),
                                        valueFormatter: (value) => `Comments: ${value}`
                                    },
                                    {
                                        data: results.spaceOverview.map((item) => item.reactions),
                                        valueFormatter: (value) => `Reactions: ${value}`
                                    }
                                ]}
                                height={300}
                                fullWidth
                            />}
                        {isChange &&
                            <LineChart
                                dataset={results.space}
                                xAxis={[{
                                    data: results.spaceOverview.map((item) => dayjs(item.date).unix()),
                                    valueFormatter: (value) => dayjs.unix(value).format("MM/DD/YYYY")
                                }]}
                                yAxis={[{
                                    min: -20,
                                }]}
                                series={[
                                    {
                                        data: results.spaceOverview.map((item, index) => {
                                            if (index > 0) {
                                                return item.newMembers
                                            } else {
                                                return 0
                                            }
                                        }),
                                        valueFormatter: (value) => `Members Change: ${value}`,
                                    },
                                    {
                                        data: results.spaceOverview.map((item, index) => {
                                            if (index > 0) {
                                                return item.newPosts
                                            } else {
                                                return 0
                                            }
                                        }),
                                        valueFormatter: (value) => `Posts Change: ${value}`,
                                    },
                                    {
                                        data: results.spaceOverview.map((item, index) => {
                                            if (index > 0) {
                                                return item.newComments
                                            } else {
                                                return 0
                                            }
                                        }),
                                        valueFormatter: (value) => `Comments Change: ${value}`,
                                    },
                                    {
                                        data: results.spaceOverview.map((item, index) => {
                                            if (index > 0) {
                                                return item.newReactions
                                            } else {
                                                return 0
                                            }
                                        }),
                                        valueFormatter: (value) => `Reactions Change: ${value}`,
                                    }
                                ]}
                                height={300}
                                fullWidth
                            />}
                        <Grid2 container spacing={3} marginTop={3}>
                            <Grid2 container spacing={3} size={{ xs: 12, sm: 12, md: 12 }}>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Post Views <Tooltip placement="top" title="Total post views for the period on this Space's landing page."><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.postViewsData[0] ? results.postViewsData[0].postViews : "0").toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                {/*<Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Pageviews <Tooltip placement="top" title="Total pageviews for the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>*/}
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Sessions <Tooltip placement="top" title="Total sessions for the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>

                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">
                                                        Total Users <Tooltip placement="top" title="Total users for the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                    </Typography>
                                                    <Typography variant="h4">{Number(results.totalUsers).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    {isChange && (<>
                                                        <Typography variant="caption">
                                                            Post Count <Tooltip placement="top" title="Total posts created in the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.periodGrowth.posts.toLocaleString()}</Typography>
                                                    </>)}
                                                    {!isChange && (<>
                                                        <Typography variant="caption">
                                                            Post Count <Tooltip placement="top" title="Total posts to date"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.endTotals.posts.toLocaleString()}</Typography>
                                                    </>)}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    {isChange && (<>
                                                        <Typography variant="caption">
                                                            Comments Count <Tooltip placement="top" title="Total comments created in the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.periodGrowth.comments.toLocaleString()}</Typography>
                                                    </>)}
                                                    {!isChange && (<>
                                                        <Typography variant="caption">
                                                            Comments Count <Tooltip placement="top" title="Total comments to date"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.endTotals.comments.toLocaleString()}</Typography>
                                                    </>)}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    {isChange && (<>
                                                        <Typography variant="caption">
                                                            Reaction Count <Tooltip placement="top" title="Total reactions in the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.periodGrowth.reactions.toLocaleString()}</Typography>
                                                    </>)}
                                                    {!isChange && (<>
                                                        <Typography variant="caption">
                                                            Reaction Count <Tooltip placement="top" title="Total reactions to date"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.endTotals.reactions.toLocaleString()}</Typography>
                                                    </>)}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    {isChange && (<>
                                                        <Typography variant="caption">
                                                            Member Count <Tooltip placement="top" title="Total members in the period"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.periodGrowth.members.toLocaleString()}</Typography>
                                                    </>)}
                                                    {!isChange && (<>
                                                        <Typography variant="caption">
                                                            Member Count <Tooltip placement="top" title="Total members to date"><InfoIcon sx={{ fontSize: "0.875rem" }} /></Tooltip>
                                                        </Typography>
                                                        <Typography variant="h4">{results.endTotals.members.toLocaleString()}</Typography>
                                                    </>)}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Grid2>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleExportCSV}
                                sx={{ marginTop: '20px' }}
                            >
                                Export CSV
                            </Button>
                        </Grid2>
                        {!isChange &&
                            <Paper elevation={5} sx={{ marginTop: '20px', height: '400px' }}>
                                <DataGrid rows={results.spaceOverview} columns={columns} />
                            </Paper>
                        }
                        {isChange &&
                            <Paper elevation={5} sx={{ marginTop: '20px', height: '400px' }}>
                                <DataGrid rows={results.spaceOverview} columns={columnsChange} />
                            </Paper>
                        }
                    </>
                ) : (
                    <></>
                )
            )}
        </Container >
    );
}

export default SpacesReport;
