import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Autocomplete,
    TextField,
    Chip,
    LinearProgress,
    Tooltip
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useUser } from '@clerk/clerk-react';

function SiteDashboard() {
    const { user } = useUser();
    const parentSite = user?.publicMetadata?.parentSite || "1";
    const singleSiteUser = user?.publicMetadata?.singleSiteUser || false;
    const singleSite = user?.publicMetadata?.site;
    const location = useLocation()
    const data = location.state || {};
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hostnameLoading, setHostnameLoading] = useState(true)
    const [startDate, setStartDate] = useState(dayjs().subtract(31, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));
    const [hostnames, setHostnames] = useState([]);
    const [site, setSite] = useState(data.site || singleSite || '');
    const underperforming = data.underperforming || false;

    const categoryColumns = [
        { field: 'category', headerName: 'Category', flex: 2, renderCell: (params) => (
            <Link 
                to={`/editorial/analytics/category`}
                state={{
                    site: site, 
                    categoryName: params.value,
                    startDate: `${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}`,
                    endDate: `${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`,
                    underperforming: underperforming
                }}
            >
                {params.value}
            </Link>
        ) },
        { field: 'views', headerName: 'Views', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        { field: 'sessions', headerName: 'Sessions', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        { field: 'count', headerName: 'Posts', flex: 1.5 }
    ];

    const contentTypeCategories = [
        { field: 'contentType', headerName: 'Content Type', flex: 2, renderCell: (params) => (
            <Link 
                to={`/editorial/analytics/content-type`}
                state={{
                    site: site, 
                    contentType: params.value,
                    startDate: `${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}`,
                    endDate: `${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`,
                    underperforming: underperforming
                }}
            >
                {params.value}
            </Link>
        ) },
        { field: 'views', headerName: 'Views', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        { field: 'sessions', headerName: 'Sessions', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        { field: 'count', headerName: 'Posts', flex: 1.5 }
    ];

    const authorColumns = [
        {
            field: 'author', headerName: 'Author', flex: 2, renderCell: (params) => (
                <Link 
                    to={`/editorial/analytics/author`}
                    state={{
                        site: site, 
                        author: params.value,
                        startDate: `${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}`,
                        endDate: `${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}`,
                        underperforming: underperforming
                    }}
                >
                    {params.value}
                </Link>
            )
        },
        { field: 'views', headerName: 'Views', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        { field: 'sessions', headerName: 'Sessions', flex: 1.5, renderCell: (params) => (
            <>
                {Number(params.value).toLocaleString()}
            </>
        ) },
        { field: 'count', headerName: 'Posts', flex: 1.5 }
    ];

    useEffect(() => {
        // Fetch audiences on component mount
        if (site) {
            setLoading(true)
            fetch(`/api/site-analytics?site=${site}${singleSiteUser ? '' : `&parentSite=${parentSite}`}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}&underperforming=${underperforming}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
                .then((res) => res.json())
                .then((data) => {
                    setResults(data)
                    setLoading(false)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        } else {
            fetch(`/api/analytics-site-lookup?parentSite=${parentSite}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
                .then((res) => res.json())
                .then((data) => {
                    let hostnameResult = []
                    data.hostnames.forEach((row => hostnameResult.push(row.hostname)))
                    setHostnames(hostnameResult || [])
                    setHostnameLoading(false)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        }
    }, [site, startDate, endDate, parentSite, singleSiteUser, underperforming]);

    return (
        <Container maxWidth="lg">
            <Grid2
                container
                spacing={3}
            >
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                    {!singleSiteUser && 
                    (<Autocomplete
                        disablePortal
                        renderInput={(params) => <TextField {...params} label="Site" />}
                        value={site}
                        options={hostnames}
                        loading={hostnameLoading}
                        onInputChange={(e, value, reason) => { if (reason === "selectOption") { setSite(value) } }}
                    />)}
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="Start Date"
                        value={startDate}
                        onChange={(newDate) => {
                            setStartDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("08-01-2024")}
                        maxDate={dayjs().subtract(2, "day")}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                            setEndDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("08-01-2024")}
                        maxDate={dayjs().subtract(1, "day")} />
                </Grid2>
            </Grid2>
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                results ? (
                    <>
                        <Grid2 container spacing={3} marginTop={3}>
                            <Grid2 container spacing={3} size={{ xs: 12, sm: 6, md: 8 }}>
                                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Content Pageviews</Typography>
                                                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                                                    {(results.viewsChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.viewsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.viewsChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.viewsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Sessions</Typography>
                                                    <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                                                    {(results.sessionsChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.sessionsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.sessionsChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.sessionsChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Story Count</Typography>
                                                    <Typography variant="h4">{results.count}</Typography>
                                                    {(results.countChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.countChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.countChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.countChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Pageviews per Story</Typography>
                                                    <Typography variant="h4">{Math.round(results.pagesPerStory * 100) / 100}</Typography>
                                                    {(results.pagesChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.pagesChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.pagesChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.pagesChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Stories per Day</Typography>
                                                    <Typography variant="h4">{Math.round(results.storiesPerDay * 100) / 100}</Typography>
                                                    {(results.postsPerDayChange > 0) && (
                                                        <Chip color='success' variant="outlined" size="small" icon={<TrendingUpIcon />} label={`${Math.round(results.postsPerDayChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                    {(results.postsPerDayChange < 0) && (
                                                        <Chip color='error' variant="outlined" size="small" icon={<TrendingDownIcon />} label={`${Math.round(results.postsPerDayChange * 100) / 100}%`} style={{ marginTop: "10px" }} />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                            <Grid2 container spacing={3} size={{ xs: 12, sm: 6, md: 4 }}>
                                <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography sx={{ fontWeight: '800' }}>Cohort benchmarks:</Typography>
                                            <Grid2 container spacing={3} size={{ xs: 12, sm: 12, md: 12 }} sx={{ marginTop: '10px' }}>
                                                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                                                    <Typography variant='caption'>Post count</Typography>
                                                    <Tooltip title={`Target: ${Math.round(results.networkAverageCount)} posts`} arrow>
                                                        <LinearProgress
                                                            variant="determinate"
                                                            color={
                                                                ((results.count / results.networkAverageCount) * 100) > 80 ? "success" : "error"
                                                            }
                                                            sx={{ height: "20px", marginTop: "10px", borderRadius: "5px" }}
                                                            value={
                                                                ((results.count / results.networkAverageCount) * 100) > 100 ? 100 : (results.count / results.networkAverageCount) * 100
                                                            } />
                                                    </Tooltip>
                                                </Grid2>
                                                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                                                    <Typography variant='caption'>Views per story</Typography>
                                                    <Tooltip title={`Target: ${Math.round(results.networkViewsPerStory).toLocaleString()} views per story`} arrow>
                                                        <LinearProgress
                                                            variant="determinate"
                                                            color={
                                                                ((results.pagesPerStory / results.networkViewsPerStory) * 100) > 80 ? "success" : "error"
                                                            }
                                                            sx={{ height: "20px", marginTop: "10px", borderRadius: "5px" }}
                                                            value={
                                                                ((results.pagesPerStory / results.networkViewsPerStory) * 100) > 100 ? 100 : (results.pagesPerStory / results.networkViewsPerStory) * 100
                                                            } />
                                                    </Tooltip>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography sx={{ fontWeight: '800' }}>Site benchmarks:</Typography>
                                            <Grid2 container spacing={3} size={{ xs: 12, sm: 12, md: 12 }} sx={{ marginTop: '10px' }}>
                                                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                                                    <Typography variant='caption'>Post count</Typography>
                                                    <Tooltip title={`Target: ${Math.round(results.normalizedHistoricTotal)} posts`} arrow>
                                                        <LinearProgress
                                                            variant="determinate"
                                                            color={
                                                                ((results.count / results.normalizedHistoricTotal) * 100) > 80 ? "success" : "error"
                                                            }
                                                            sx={{ height: "20px", marginTop: "10px", borderRadius: "5px" }}
                                                            value={
                                                                ((results.count / results.normalizedHistoricTotal) * 100) > 100 ? 100 : (results.count / results.normalizedHistoricTotal) * 100
                                                            } />
                                                    </Tooltip>
                                                </Grid2>
                                                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                                                    <Typography variant='caption'>Views per story</Typography>
                                                    <Tooltip title={`Target: ${Math.round(results.normalizedHistoricViewsPerStory).toLocaleString()} views per story`} arrow>
                                                        <LinearProgress
                                                            variant="determinate"
                                                            color={
                                                                ((results.pagesPerStory / results.normalizedHistoricViewsPerStory) * 100) > 80 ? "success" : "error"
                                                            }
                                                            sx={{ height: "20px", marginTop: "10px", borderRadius: "5px" }}
                                                            value={
                                                                ((results.pagesPerStory / results.normalizedHistoricViewsPerStory) * 100) > 100 ? 100 : (results.pagesPerStory / results.normalizedHistoricViewsPerStory) * 100
                                                            } />
                                                    </Tooltip>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Typography variant="h4" style={{ marginTop: '20px' }}>Top Categories</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.categoryResults} columns={categoryColumns} />

                        <Typography variant="h4" style={{ marginTop: '20px' }}>Top Authors</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.authorResults} columns={authorColumns} />

                        <Typography variant="h4" style={{ marginTop: '20px' }}>Top Content Types</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={results.contentTypeResults} columns={contentTypeCategories} />
                    </>
                ) : (
                    <></>
                )
            )}
        </Container >
    );
}

export default SiteDashboard;
