import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Alert,
  Button,
  Autocomplete,
  TextField
} from '@mui/material';

function ElectionWidgets() {

  const [riding, setRiding] = useState([{ id: 1, label: "Ajax" }])
  const [alert, setAlert] = useState(false);

  const ridings = [
    { id: 1, label: "Ajax" },
    { id: 2, label: "Algoma-Manitoulin" },
    { id: 3, label: "Aurora-Oak Ridges-Richmond Hill" },
    { id: 4, label: "Barrie-Innisfil" },
    { id: 5, label: "Barrie-Springwater-Oro-Medonte" },
    { id: 6, label: "Bay of Quinte" },
    { id: 7, label: "Beaches-East York" },
    { id: 8, label: "Brampton Centre" },
    { id: 9, label: "Brampton East" },
    { id: 10, label: "Brampton North" },
    { id: 11, label: "Brampton South" },
    { id: 12, label: "Brampton West" },
    { id: 13, label: "Brantford-Brant" },
    { id: 14, label: "Bruce-Grey-Owen Sound" },
    { id: 15, label: "Burlington" },
    { id: 16, label: "Cambridge" },
    { id: 17, label: "Carleton" },
    { id: 18, label: "Chatham-Kent-Leamington" },
    { id: 19, label: "Davenport" },
    { id: 20, label: "Don Valley East" },
    { id: 21, label: "Don Valley North" },
    { id: 22, label: "Don Valley West" },
    { id: 23, label: "Dufferin-Caledon" },
    { id: 24, label: "Durham" },
    { id: 25, label: "Eglinton-Lawrence" },
    { id: 26, label: "Elgin-Middlesex-London" },
    { id: 27, label: "Essex" },
    { id: 28, label: "Etobicoke Centre" },
    { id: 29, label: "Etobicoke-Lakeshore" },
    { id: 30, label: "Etobicoke North" },
    { id: 31, label: "Flamborough-Glanbrook" },
    { id: 32, label: "Glengarry-Prescott-Russell" },
    { id: 33, label: "Guelph" },
    { id: 34, label: "Haldimand-Norfolk" },
    { id: 35, label: "Haliburton-Kawartha Lakes-Brock" },
    { id: 36, label: "Hamilton Centre" },
    { id: 37, label: "Hamilton East-Stoney Creek" },
    { id: 38, label: "Hamilton Mountain" },
    { id: 39, label: "Hamilton West-Ancaster-Dundas" },
    { id: 40, label: "Hastings-Lennox and Addington" },
    { id: 41, label: "Humber River-Black Creek" },
    { id: 42, label: "Huron-Bruce" },
    { id: 43, label: "Kanata-Carleton" },
    { id: 44, label: "Kenora-Rainy River" },
    { id: 45, label: "King-Vaughan" },
    { id: 46, label: "Kingston and the Islands" },
    { id: 47, label: "Kitchener Centre" },
    { id: 48, label: "Kitchener-Conestoga" },
    { id: 49, label: "Kitchener South-Hespeler" },
    { id: 50, label: "Lambton-Kent-Middlesex" },
    { id: 51, label: "Lanark-Frontenac-Kingston" },
    { id: 52, label: "Leeds-Grenville-Thousand Islands and Rideau Lakes" },
    { id: 53, label: "London-Fanshawe" },
    { id: 54, label: "London North Centre" },
    { id: 55, label: "London West" },
    { id: 56, label: "Markham-Stouffville" },
    { id: 57, label: "Markham-Thornhill" },
    { id: 58, label: "Markham-Unionville" },
    { id: 59, label: "Milton" },
    { id: 60, label: "Mississauga Centre" },
    { id: 61, label: "Mississauga East-Cooksville" },
    { id: 62, label: "Mississauga-Erin Mills" },
    { id: 63, label: "Mississauga-Lakeshore" },
    { id: 64, label: "Mississauga-Malton" },
    { id: 65, label: "Mississauga-Streetsville" },
    { id: 66, label: "Nepean" },
    { id: 67, label: "Newmarket-Aurora" },
    { id: 68, label: "Niagara Centre" },
    { id: 69, label: "Niagara Falls" },
    { id: 70, label: "Niagara West" },
    { id: 71, label: "Nickel Belt" },
    { id: 72, label: "Nipissing" },
    { id: 73, label: "Northumberland-Peterborough South" },
    { id: 74, label: "Oakville" },
    { id: 75, label: "Oakville North-Burlington" },
    { id: 76, label: "Orléans" },
    { id: 77, label: "Oshawa" },
    { id: 78, label: "Ottawa Centre" },
    { id: 79, label: "Ottawa South" },
    { id: 80, label: "Ottawa-Vanier" },
    { id: 81, label: "Ottawa West-Nepean" },
    { id: 82, label: "Oxford" },
    { id: 83, label: "Parkdale-High Park" },
    { id: 84, label: "Parry Sound-Muskoka" },
    { id: 85, label: "Perth-Wellington" },
    { id: 86, label: "Peterborough-Kawartha" },
    { id: 87, label: "Pickering-Uxbridge" },
    { id: 88, label: "Renfrew-Nipissing-Pembroke" },
    { id: 89, label: "Richmond Hill" },
    { id: 90, label: "St. Catharines" },
    { id: 91, label: "Sarnia-Lambton" },
    { id: 92, label: "Sault Ste. Marie" },
    { id: 93, label: "Scarborough-Agincourt" },
    { id: 94, label: "Scarborough Centre" },
    { id: 95, label: "Scarborough-Guildwood" },
    { id: 96, label: "Scarborough North" },
    { id: 97, label: "Scarborough-Rouge Park" },
    { id: 98, label: "Scarborough Southwest" },
    { id: 99, label: "Simcoe-Grey" },
    { id: 100, label: "Simcoe North" },
    { id: 101, label: "Spadina-Fort York" },
    { id: 102, label: "Stormont-Dundas-South Glengarry" },
    { id: 103, label: "Sudbury" },
    { id: 104, label: "Thornhill" },
    { id: 105, label: "Thunder Bay-Atikokan" },
    { id: 106, label: "Thunder Bay-Superior North" },
    { id: 107, label: "Timiskaming-Cochrane" },
    { id: 108, label: "Timmins" },
    { id: 109, label: "Toronto Centre" },
    { id: 110, label: "Toronto-Danforth" },
    { id: 111, label: "Toronto-St. Paul's" },
    { id: 112, label: "University-Rosedale" },
    { id: 113, label: "Vaughan-Woodbridge" },
    { id: 114, label: "Waterloo" },
    { id: 115, label: "Wellington-Halton Hills" },
    { id: 116, label: "Whitby" },
    { id: 117, label: "Willowdale" },
    { id: 118, label: "Windsor-Tecumseh" },
    { id: 119, label: "Windsor West" },
    { id: 120, label: "York Centre" },
    { id: 121, label: "York-Simcoe" },
    { id: 122, label: "York South-Weston" },
    { id: 123, label: "Kiiwetinoong" },
    { id: 124, label: "Mushkegowuk-James Bay" },
  ]

  const singleRiding = `<div class="lazyload" data-method="GET" style="min-height:300px" data-endpoint="https://elections.villagemedia.ca/on25" data-success="VM.setup"></div>
  <div class="lazyload" data-method="GET" data-endpoint="https://elections.villagemedia.ca/on25/riding/${riding[0]?.id}" data-success="VM.setup"></div>`

  const multiRiding = `
  <div class="row">
  <div class="lazyload col-md-12 col-sm-12" data-method="GET" style="min-height:300px" data-endpoint="https://elections.villagemedia.ca/on25" data-success="VM.setup"></div>
    ${riding.length > 1 ? riding.map((item) => `<div class="col-md-6 col-sm-6 lazyload" data-method="GET" data-endpoint="https://elections.villagemedia.ca/on25/riding/${item.id}" data-success="VM.setup"></div>`).join(`
      `) : ''}
  </div>`

  const copyToClipboard = (adCode) => {
    navigator.clipboard.writeText(adCode);
    setAlert(true);
  };

  return (
    <Container maxWidth="lg">
      <Box component="form">
        <Autocomplete
          id="ridings"
          options={ridings}
          multiple
          value={riding}
          onChange={(e, value) => setRiding(value)}
          renderInput={params => (
            <TextField
              {...params}
              label="Select Site"
              variant="outlined"
            />
          )}
        />
      </Box>

      <Typography variant="h5" mt={3}>Election Widget Tag:</Typography>
      {riding.length === 1 && (
        <Box>
          {alert && (
            <Alert severity='success'>Tag copied to Clipboard</Alert>
          )}
          <Button onClick={() => copyToClipboard(singleRiding)}>Copy to Clipboard</Button>
          <pre>
            <code>
              {singleRiding}
            </code>
          </pre>
        </Box>
      )}
      {riding.length > 1 && (
        <Box>
          {alert && (
            <Alert severity='success'>Tag copied to Clipboard</Alert>
          )}
          <Button onClick={() => copyToClipboard(multiRiding)}>Copy to Clipboard</Button>
          <pre>
            <code>
              {multiRiding}
            </code>
          </pre>
        </Box>
      )}
    </Container>
  );
}

export default ElectionWidgets;
