import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Autocomplete,
    TextField,
    Switch
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useUser } from '@clerk/clerk-react';
import { DataGrid } from '@mui/x-data-grid';

function SpotlightDashboard() {
    const { user } = useUser();
    const parentSite = user?.publicMetadata?.parentSite || "1";
    const singleSiteUser = user?.publicMetadata?.singleSiteUser || false;
    const singleSite = user?.publicMetadata?.site;
    const location = useLocation()
    const data = location.state || {};
    const [results, setResults] = useState(null);
    const [content, setContent] = useState(null)
    const [loading, setLoading] = useState(false);
    const [hostnameLoading, setHostnameLoading] = useState(true)
    const [startDate, setStartDate] = useState(dayjs().subtract(31, 'day'));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day'));
    const [hostnames, setHostnames] = useState([]);
    const [site, setSite] = useState(data.site || singleSite || '');
    const [filters, setFilters] = useState({ underperforming: false, threshold: 200 });
    const [showFilters, setShowFilters] = useState(false);

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            flex: 3,
            renderCell: (params) => (
                <>{params.value}</>
            )
        },
        {
            field: 'views', headerName: 'Views', flex: 1, renderCell: (params) => (
                <>
                    {Number(params.value).toLocaleString()}
                </>
            )
        }
    ];

    useEffect(() => {
        console.log(filters)
        if (site) {
            const fetchData = async () => {
                try {
                    setLoading(true)
                    const siteData = await fetch(`/api/site-analytics?spotlight=true&site=${site}${singleSiteUser ? '' : `&parentSite=${parentSite}`}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}&underperforming=${filters.underperforming}&threshold=${filters.threshold}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`);
                    const siteResults = await siteData.json();
                    setResults(siteResults);

                    const contentData = await fetch(`/api/listings-analytics?categoryName=Spotlight&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f&site=${site}&startDate=${dayjs(startDate).get('year')}-${dayjs(startDate).get('month') + 1}-${dayjs(startDate).get('date')}&endDate=${dayjs(endDate).get('year')}-${dayjs(endDate).get('month') + 1}-${dayjs(endDate).get('date')}&underperforming=${filters.underperforming}&threshold=${filters.threshold}`)
                    const contentResults = await contentData.json();
                    setContent(contentResults);

                    setLoading(false)
                } catch (error) {
                    console.log(error);
                }
            }
            fetchData();
        } else {
            fetch(`/api/analytics-site-lookup?parentSite=${parentSite}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`)
                .then((res) => res.json())
                .then((data) => {
                    let hostnameResult = []
                    data.hostnames.forEach((row => hostnameResult.push(row.hostname)))
                    setHostnames(hostnameResult || [])
                    setHostnameLoading(false)
                })
                .catch((error) => console.error('Error fetching audiences:', error));
        }
    }, [site, startDate, endDate, parentSite, singleSiteUser, filters]);

    return (
        <Container maxWidth="lg">
            <Grid2
                container
                spacing={3}
            >
                <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
                    {!singleSiteUser &&
                        (<Autocomplete
                            disablePortal
                            renderInput={(params) => <TextField {...params} label="Site" />}
                            value={site}
                            options={hostnames}
                            loading={hostnameLoading}
                            onInputChange={(e, value, reason) => { if (reason === "selectOption") { setSite(value) } }}
                        />)}
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="Start Date"
                        value={startDate}
                        onChange={(newDate) => {
                            setStartDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("08-01-2024")}
                        maxDate={dayjs().subtract(2, "day")}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                    <DatePicker
                        sx={{ width: "100%" }}
                        label="End Date"
                        value={endDate}
                        onChange={(newDate) => {
                            setEndDate(newDate)
                            console.log(startDate)
                        }}
                        minDate={dayjs("08-01-2024")}
                        maxDate={dayjs().subtract(1, "day")} />
                </Grid2>
                <Grid2 size={{ xd: 12, sm: 12, md: 12 }} alignContent="center">
                    <Typography variant="caption">
                        Show Filters:
                    </Typography>
                    <Switch
                        checked={showFilters}
                        onChange={(event) => setShowFilters(event.target.checked)}
                        label="Show filters"
                    />
                </Grid2>
            </Grid2>
            {showFilters && (
                    <Grid2 container spacing={5}>
                        <Grid2 size={{ xs: 12, sm: 6, md: 3 }} alignContent="center">
                            <Typography variant="caption">
                                Underperforming:
                            </Typography>
                            <Switch
                                checked={filters.underperforming}
                                onChange={(event) => setFilters({ ...filters, underperforming: event.target.checked })}
                                label="Show underperforming content"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 3 }} alignContent="center">
                            <TextField label="Threshold" value={filters.threshold} onChange={(e) => setFilters({ ...filters, threshold: e.target.value })} />
                        </Grid2>
                    </Grid2>
                )}
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                results && content ? (
                    <>
                        <Grid2 container spacing={3} marginTop={3}>
                            <Grid2 container spacing={3} size={{ xs: 12, sm: 12, md: 12 }}>
                                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Content Pageviews</Typography>
                                                    <Typography variant="h4">{Number(results.totalViews).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Sessions</Typography>
                                                    <Typography variant="h4">{Number(results.totalSessions).toLocaleString()}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Story Count</Typography>
                                                    <Typography variant="h4">{results.count}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Pageviews per Story</Typography>
                                                    <Typography variant="h4">{Math.round(results.pagesPerStory * 100) / 100}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid2 container spacing={0} >
                                                <Grid2 container direction="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{ mx: 'auto' }}
                                                >
                                                    <Typography variant="caption">Stories per Day</Typography>
                                                    <Typography variant="h4">{Math.round(results.storiesPerDay * 100) / 100}</Typography>
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>Content Breakdown</Typography>
                        <DataGrid style={{ marginTop: '20px', height: '400px' }} rows={content.authorResults} columns={columns} />
                    </>
                ) : (
                    <></>
                )
            )}
        </Container >
    );
}

export default SpotlightDashboard;
