import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

const contentGoalOptions = [
  'Increase brand awareness',
  'Provide value/solve a problem',
  'Educate the audience',
  'Showcase industry expertise',
  'Build community engagement',
  'Inspire',
];

const SpotlightForm = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    businessCategory: '',
    productServiceDescription: '',
    targetAudience: '',
    contentGoal: '',
    sellingPoints: '',
    specialThemes: '',
    location: ''
  });

  const [result, setResult] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = "/api/spotlight-generator"
    const body = JSON.stringify(formData);
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    })
      .then((response) => response.json())
      .then((data) => {
        setResult(data);
      })
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="companyName"
        label="Company Name"
        name="companyName"
        value={formData.companyName}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="businessCategory"
        label="Business Category (e.g., Restaurant, retail, home services, technology, etc."
        name="businessCategory"
        value={formData.businessCategory}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="productServiceDescription"
        label="Product/Service Description (1-2 sentences)"
        name="productServiceDescription"
        value={formData.productServiceDescription}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="targetAudience"
        label="Target Audience: (Who are you trying to reach? e.g., local families, young professionals, etc.)"
        name="targetAudience"
        value={formData.targetAudience}
        onChange={handleChange}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="content-goal-label">Content Goal</InputLabel>
        <Select
          labelId="content-goal-label"
          required
          id="contentGoal"
          name="contentGoal"
          value={formData.contentGoal}
          label="What do you want to achieve?"
          onChange={handleChange}
        >
          {contentGoalOptions.map((goal) => (
            <MenuItem key={goal} value={goal}>
              {goal}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        margin="normal"
        fullWidth
        id="sellingPoints"
        label="What makes your business special? (e.g., high-quality ingredients, friendly service, expert knowledge)"
        name="sellingPoints"
        value={formData.sellingPoints}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="specialThemes"
        label="Special Themes or Topics (Optional)"
        name="specialThemes"
        value={formData.specialThemes}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="location"
        label="Location (Optional)"
        name="location"
        value={formData.location}
        onChange={handleChange}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
      {result && (
        <>
          <Typography variant="h4">Ideas:</Typography>
          <ReactMarkdown children={result.result} />
        </>
      )}
    </Box>
  );
};

export default SpotlightForm;