import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Alert,
  Button,
  Autocomplete,
  TextField
} from '@mui/material';

function ElectionPages() {

  const [site, setSite] = useState({ label: "Please choose a site to start" });
  const [alert, setAlert] = useState({});
  const sites = [
    {
      id: 0,
      label: "GuelphToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22221295"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22221295/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 1,
      label: "EloraFergusToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22301015"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22301015/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 2,
      label: "StratfordToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22221439"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22221439/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 3,
      label: "CambridgeToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22301053"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22301053/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 4,
      label: "BurlingtonToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22242069"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22242069/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 5,
      label: "Oakville News",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22304490"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22304490/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 6,
      label: "MiltonToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22301098"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22301098/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 7,
      label: "HaltonHillsToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22300243"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22300243/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 8,
      label: "BarrieToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22280046"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22280046/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 9,
      label: "InnisfilToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22281890"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22281890/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 10,
      label: "CollingwoodToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22280942"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22280942/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 11,
      label: "OrilliaMatters.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22281684"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22281684/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 12,
      label: "MidlandToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22281816"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22281816/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 13,
      label: "AuroraToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22303193"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22303193/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 14,
      label: "NewmarketToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22285717"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22285717/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 15,
      label: "BradfordToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22305767"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22305767/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 16,
      label: "Niagara-on-the-Lake Local",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22320924"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22320924/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 17,
      label: "ThoroldToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22320924"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22320924/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 18,
      label: "PelhamToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22320924"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22320924/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 19,
      label: "FlamboroughToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22321703"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22321703/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 20,
      label: "ElliotLakeToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22299440"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22299440/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 21,
      label: "SooToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22299440"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22299440/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 22,
      label: "Sudbury.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22242162"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22242162/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 23,
      label: "TimminsToday.com",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22303758"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22303758/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 24,
      label: "BayToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22304716"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22304716/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    },
    {
      id: 25,
      label: "TorontoToday.ca",
      embed: `<div class="flourish-embed flourish-cards" data-src="visualisation/22226033"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22226033/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`
    }
  ]

  const candidatesMarkup = `${site.embed}

<h2 class="title" style="margin-top:20px;">Latest election news</h2>`

  const ptMarkup = `<p style="margin-top:20px;"><a href="https://www.parliamenttoday.ca/"><img alt="The Trillium" class="logo" height="40" id="" src="https://www.vmcdn.ca/files/parliamenttoday/images/site/logo_parliament_today_horizontal.svg" /></a></p>

<p>For more in-depth coverage of federal politics, be sure to <a href="https://www.parliamenttoday.ca/">check out Parliament Today</a>.</p>

<hr class="thick" />

<div style="margin-top:20px" class="flourish-embed flourish-chart" data-src="visualisation/22161654"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22161654/thumbnail" width="100%" alt="chart visualization" /></noscript></div>

<div style="margin-top:20px" class="flourish-embed flourish-cards" data-src="visualisation/22157327"><script src="https://public.flourish.studio/resources/embed.js"></script><noscript><img src="https://public.flourish.studio/visualisation/22157327/thumbnail" width="100%" alt="cards visualization" /></noscript></div>`

  const sidebarWidget = `<ul>
  <li><a href="https://www.elections.ca/scripts/vis/FindFutureED?L=e&amp;PAGEID=150" target="_blank">Find your electoral district</a></li>
  <li><a href="https://www.elections.ca/content.aspx?section=vot&amp;dir=reg&amp;document=index&amp;lang=e" target="_blank">Register to vote</a></li>
  <li><a href="https://www.elections.ca/content.aspx?section=vot&amp;dir=vop&amp;document=index&amp;lang=e" target="_blank">Voting checklist</a></li>
  <li><a href="https://www.elections.ca/content.aspx?section=vot&amp;dir=vote&amp;document=index&amp;lang=e" target="_blank">Ways to vote</a></li>
  <li><a href="https://www.elections.ca/content.aspx?section=vot&amp;dir=ids&amp;document=index&amp;lang=e" target="_blank">Identification to bring</a></li>
</ul>`

  const copyToClipboard = (adCode, value) => {
    navigator.clipboard.writeText(adCode);
    setAlert({ type: value, status: true });
  };

  return (
    <Container maxWidth="lg">
      <Box component="form">
        <Autocomplete
          id="sites"
          options={sites}
          value={site}
          onChange={(e, value, reason) => {
            if (reason === 'selectOption') {
              setSite(value)
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Select Site"
              variant="outlined"
            />
          )}
        />
      </Box>

      {site.id !== undefined && (
        <>
          <Typography variant="h4" mt={3}>{site.label} Election Page Instructions</Typography>
          <Typography mt={3}>Start by adding the shared category to the site and use the drop down menu above to generate the widget codes. Then add a full-width column to the page and insert the election banner image.</Typography>

          <Typography variant="h5" mt={3}>Image Widget settings:</Typography>
          <Typography mt={3}>Name: Canada Votes</Typography>
          <Typography>Image URL: https://www.vmcdn.ca/f/files/shared/site-layout/canada_votes_page_header_960x160.jpg</Typography>
          <Typography>Image Width: 645</Typography>

          <Typography mt={3}>After adding the image widget, create a new WYSIWYG widget (hide title) and paste the "Candidates Widget" tag into the description's html editor.</Typography>

          {site.embed && (
            <>
              <Typography variant="h5" mt={3}>Candidates Widget:</Typography>
              <Box>
                {alert.type === 'candidate' && (
                  <Alert severity='success'>Tag copied to Clipboard</Alert>
                )}
                <Button onClick={() => copyToClipboard(candidatesMarkup, 'candidate')}>Copy to Clipboard</Button>
                <pre>
                  <code>
                    {candidatesMarkup}
                  </code>
                </pre>
              </Box>
            </>)}

          <Typography mt={3}>Next, create a two-column row for the content widgets.</Typography>

          <Typography variant="h5" mt={3}>Content List settings (left column):</Typography>
          <Typography mt={3}>Name: Latest election news</Typography>
          <Typography>Category: {site.label}: 2025 Federal Election News</Typography>
          <Typography>Items to Show: 14</Typography>
          <Typography>Items to Skip: 0</Typography>
          <Typography>Total Items: 25</Typography>
          <Typography>Layout: Large thumb, rest text</Typography>
          <Typography>Options: Display in Card Format, Hide Title, Hide More Link, Hide Intro (if applicable)</Typography>

          <Typography variant="h5" mt={3}>Content List settings (left column):</Typography>
          <Typography mt={3}>Name: Latest election news</Typography>
          <Typography>Category: {site.label}: 2025 Federal Election News</Typography>
          <Typography>Items to Show: 11</Typography>
          <Typography>Items to Skip: 14</Typography>
          <Typography>Total Items: 25</Typography>
          <Typography>Layout: All small thumbs</Typography>
          <Typography>Read More Title: More election coverage </Typography>
          <Typography>Options: Display in Card Format, Hide Title, Hide Intro (if applicable)</Typography>

          <Typography mt={3}>After creating those widgets, create one more full-column row. In that row, add a Wysiwyg widget (hide title) and paste in the Parliament Today Widget.</Typography>

          {site.embed && (
            <>
              <Typography variant="h5" mt={3}>Parliament Today Widget:</Typography>
              <Box>
                {alert.type === 'parliament' && (
                  <Alert severity='success'>Tag copied to Clipboard</Alert>
                )}
                <Button onClick={() => copyToClipboard(ptMarkup, 'parliament')}>Copy to Clipboard</Button>
                <pre>
                  <code>
                    {ptMarkup}
                  </code>
                </pre>
              </Box>
            </>
          )}

          <Typography mt={3}>Next, save the widgets and refresh the page.</Typography>

          <Typography>If everything looks good, edit the sidebar widget area and create a GAM Responsive Slot.</Typography>

          <Typography variant="h5" mt={3}>GAM Responsive Slot settings:</Typography>
          <Typography mt={3}>Name: NET_ElectionResults</Typography>
          <Typography>Desktop (992px +): [[300, 600], [300, 250]]</Typography>
          <Typography>Minimum Height: 250</Typography>
          <Typography>Options: Disable lazy loading (only if header bidding is enabled)</Typography>

          <Typography mt={3}>Next, create a WYSIWYG widget with the title "Election tools". Do not hide the title and paste the Sidebar widget code into the description's source editor.</Typography>
          <Typography mt={3}>The wysiwyg widget should have the Display in Card Format option enabled and the following CSS classes: card card-body card-body-text card-text-lg</Typography>

          {site.embed && (
            <>
              <Typography variant="h5" mt={3}>Sidebar Widget:</Typography>
              <Box>
                {alert.type === 'sidebar' && (
                  <Alert severity='success'>Tag copied to Clipboard</Alert>
                )}
                <Button onClick={() => copyToClipboard(sidebarWidget, 'sidebar')}>Copy to Clipboard</Button>
                <pre>
                  <code>
                    {sidebarWidget}
                  </code>
                </pre>
              </Box>
            </>
          )}

          <Typography mt={3}>Finally, save the widgets and refresh the page to make sure all elements display properly.</Typography>
        </>
      )}
    </Container>
  );
}

export default ElectionPages;
