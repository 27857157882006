import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, TextField, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Autocomplete, Grid2, Card, CardContent, Snackbar, Alert } from '@mui/material';
import generatePDF from 'react-to-pdf';
import DownloadIcon from '@mui/icons-material/Download';

const BusinessDashboard = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customEventLabel, setCustomEventLabel] = useState('');
  const [startDate, setStartDate] = useState('2024-10-06');
  const [endDate, setEndDate] = useState('2024-11-06');
  const [segments, setSegments] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [segment, setSegment] = useState();
  const [segmentSize, setSegmentSize] = useState();

  useEffect(() => {
    // Fetch audiences on component mount
    fetch('/api/business-lookup?api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f')
      .then((res) => res.json())
      .then((data) => {
        let businessResult = []
        data.results.forEach((row => businessResult.push(row.name)))
        setBusinesses(businessResult || []);
      })
      .catch((error) => console.error('Error fetching audiences:', error));
    fetch('/api/audience-segments?api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f')
      .then((res) => res.json())
      .then((data) => {
        let segmentResults = data.data;
        setSegments(segmentResults || []);
        console.log(segmentResults);
      })
      .catch((error) => console.error('Error fetching audiences:', error));
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/analytic?startDate=${startDate}&endDate=${endDate}&customEventLabel=${customEventLabel}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`);
      const data = await response.json();
      console.log(data)
      setAnalyticsData(data);
      if (segment) {
        const segmentResponse = await fetch(`/api/audience-segments?segment=${segment}&api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f`);
        const segmentData = await segmentResponse.json();
        setSegmentSize(segmentData.size)
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
  };



  const targetRef = useRef();

  return (
    <Container maxWidth="lg" ref={targetRef}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '20px' }}>
        <Alert severity='info'>
          The content on this page is best viewed when searching for 30 day time periods. Selecting longer time periods can result in inaccurate results. Searching a period of time before August 1, 2024 can also result in inaccurate results.
        </Alert>
        <Paper>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
          />
        </Paper>
        <Paper>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
          />
        </Paper>
        {businesses && (
          <>
            <Paper>
              <Autocomplete
                disablePortal
                options={businesses}
                filterOptions={(options, { inputValue }) =>
                  inputValue.length < 2 ? [] : options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase())) // Display only 100 options after 2 characters
                }
                noOptionsText={"No results found. Please type the business name as it appears in the directory."}
                renderInput={(params) => <TextField {...params} label="Business Name" />}
                onInputChange={(e, value) => setCustomEventLabel(value)}
              />
            </Paper>
            <Paper>
              <Autocomplete
                disablePortal
                options={segments}
                filterOptions={(options, { inputValue }) =>
                  inputValue.length < 2 ? [] : options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) // Display only 100 options after 2 characters
                }
                renderInput={(params) => <TextField {...params} label="Segment Name" />}
                noOptionsText={"No results found. Please type the segment name as it appears in GAM."}
                onChange={(e, value, reason) => {
                  if (reason === "selectOption" || reason === "clear") {
                    setSegment(value.id);
                  }
                }}
              />
            </Paper>
            <Button type="submit" variant="contained" color="primary">
              Create Report
            </Button>
          </>
        )}
      </form>

      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        analyticsData ? (
          <>
            {segmentSize && (
              <Grid2 container spacing={3} marginTop={3}>
                <Grid2 container spacing={3} size={{ xs: 12, sm: 12, md: 12 }}>
                  <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid2 container spacing={0} >
                          <Grid2 container direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ mx: 'auto' }}
                          >
                            <Typography variant="caption">Segment Size</Typography>
                            <Typography variant="h4">{Number(segmentSize).toLocaleString()}</Typography>
                          </Grid2>
                        </Grid2>
                      </CardContent>
                    </Card>
                  </Grid2>
                </Grid2>
              </Grid2>
            )}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Metric</TableCell>
                    <TableCell>Profile</TableCell>
                    <TableCell>Listings</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analyticsData.result.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  ) : (
                    analyticsData.result.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{data.metric}</TableCell>
                        <TableCell>{data.profile}</TableCell>
                        <TableCell>{data.listings}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h5" align="center" gutterBottom style={{ marginTop: '40px' }}>
              Classified Views
            </Typography>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Page Title</TableCell>
                    <TableCell>Views</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analyticsData.classifiedResults.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  ) : (
                    analyticsData.classifiedResults.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link href={`https://${data.url}`} target="_blank" rel="noopener">
                            {data.classified}
                          </Link>
                        </TableCell>
                        <TableCell>{data.views}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h5" align="center" gutterBottom style={{ marginTop: '40px' }}>
              Related Content Views
            </Typography>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Page Title</TableCell>
                    <TableCell>Views</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analyticsData.spotlightResults.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  ) : (
                    analyticsData.spotlightResults.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Link href={`https://${data.url}`} target="_blank" rel="noopener">
                            {data.spotlight}
                          </Link>
                        </TableCell>
                        <TableCell>{data.views}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="body1" align="center" color="textSecondary">
            No data available
          </Typography>
        )
      )}
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Button
          sx={{ position: "fixed", bottom: "20", right: "20" }}
          onClick={() => {
            generatePDF(targetRef, { filename: `business-report-${customEventLabel}-${startDate}-${endDate}.pdf` })
          }}
        >
          <DownloadIcon sx={{ fontSize: "2rem" }} />
        </Button>
      </Snackbar>
    </Container>
  );
};

export default BusinessDashboard;