import * as React from 'react';
import { AppProvider } from '@toolpad/core/react-router-dom';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HomeIcon from '@mui/icons-material/Home';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import TableChartIcon from '@mui/icons-material/TableChart';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Outlet } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useUser } from '@clerk/clerk-react';
import AddIcon from '@mui/icons-material/Add';

const NAVIGATION = [
  {
    kind: 'header',
    title: 'Main items',
  },
  {
    title: 'Home',
    icon: <HomeIcon />,
  },
  {
    title: 'Spaces',
    segment: 'spaces',
    icon: <TableChartIcon />,
    children: [
      {
        segment: 'analytics',
        title: 'Analytics',
        icon: <FingerprintIcon />,
        roles: ['admin', 'design', 'editorial']
      },
    ]
  },
  {
    title: 'Editorial',
    segment: 'editorial',
    icon: <NewspaperIcon />,
    children: [
      {
        segment: 'elections',
        title: 'Election Results',
        icon: <MarkEmailReadIcon />,
        roles: ['admin', 'design', 'editorial']
      },
      {
        segment: 'federal-elections',
        title: 'Election Pages',
        icon: <MarkEmailReadIcon />,
        roles: ['admin', 'design', 'editorial']
      },
      {
        segment: 'signup-forms',
        title: 'Signup Forms',
        icon: <MarkEmailReadIcon />,
        roles: ['admin', 'design', 'editorial', 'partner']
      },
      {
        segment: 'analytics',
        title: 'Site Analytics',
        icon: <AutoGraphIcon />,
        roles: ['admin', 'editorial', 'partner']
      },
      {
        segment: 'network',
        title: 'Network Analytics',
        icon: <AccountTreeIcon />,
        roles: ['admin', 'editorial', 'partner']
      },
      {
        segment: 'readers',
        title: 'Readers',
        icon: <FingerprintIcon />,
        roles: ['admin', 'partner']
      },
      {
        segment: 'video-report',
        title: 'Video Content',
        icon: <FeaturedVideoIcon />,
        roles: ['admin']
      },
      {
        segment: 'streaming-report',
        title: 'Video Completion',
        icon: <OndemandVideoIcon />,
        roles: ['admin']
      }
    ]
  },
  {
    title: 'Platform & Design',
    segment: 'platform-design',
    icon: <DesignServicesIcon />,
    children: [
      {
        segment: 'email-ads',
        title: 'Email Ads',
        icon: <AdUnitsIcon />,
        roles: ['admin', 'design', 'partner']
      },
      {
        segment: 'mailchimp-widgets',
        title: 'MailChimp Widgets',
        icon: <MailOutlineIcon />,
        roles: ['admin', 'design', 'partner']
      },
      {
        segment: 'mailchimp-dashboard',
        title: 'MailChimp Dashboard',
        icon: <TableChartIcon />,
        roles: ['admin', 'design']
      },
      {
        segment: 'mailchimp-audiences',
        title: 'MailChimp Audiences',
        icon: <ContactMailIcon />,
        roles: ['admin', 'design']
      },
      {
        segment: 'favicon',
        title: 'Favicon Generator',
        icon: <AddIcon />,
        roles: ['admin', 'design']
      }
    ]
  },
  {
    title: 'Client Services',
    segment: 'client-services',
    icon: <LoyaltyIcon />,
    children: [
      {
        segment: 'interstitial-ads',
        title: 'Interstitial Ads',
        icon: <HighlightAltIcon />,
        roles: ['admin', 'sales']
      },
      {
        segment: 'business-dashboard',
        title: 'Business Dashboard',
        icon: <LeaderboardIcon />,
        roles: ['admin', 'sales']
      },
      {
        segment: 'display-report',
        title: 'Display Report',
        icon: <AdsClickIcon />,
        roles: ['admin', 'sales']
      },
    ]
  },
];

const filterNavigation = (navItems, role) => {
  return navItems
    .filter(item => !item.roles || item.roles.includes(role)) // Filter based on roles
    .map(item => ({
      ...item,
      children: item.children ? filterNavigation(item.children, role) : undefined, // Recursively filter children
    }))
    .filter(item => item.children === undefined || item.children.length > 0); // Remove empty parent items
};

const BRANDING = {
  title: 'Village Media Tools',
};

export default function App() {

  // Example: Assuming 'role' is the user's role (e.g., 'admin', 'editor', etc.)
  const { user } = useUser();
  const role = user?.publicMetadata?.role; // Replace with dynamic role
  const filteredNavigation = filterNavigation(NAVIGATION, role);

  return (

      <AppProvider navigation={filteredNavigation} branding={BRANDING}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Outlet />
        </LocalizationProvider>
      </AppProvider>
  );
}
